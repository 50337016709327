import tw, { styled } from 'twin.macro'
import { Splide } from '@splidejs/react-splide'

export const Root = styled.div`
  ${tw`
w-full
bg-orange-lighter

pt-12
pb-8
lg:pt-16
lg:pb-12
`}
`

export const SliderContainer = styled(Splide)`
  ${tw`
w-full
ml-auto
`}
`
