import React from 'react'
import { Root } from './style'
import PropTypes from 'prop-types'

const TestimonialCard = ({ name, jobTitle, quote, image }) => {
  return (
    <Root dir={'ltr'}>
      <div className={'flex content-center items-center gap-x-2.5'}>
        <div className={'h-12 w-12 overflow-hidden rounded-full'}>
          {image && (
            <img
              className={'object-cover rounded-full'}
              src={image.publicUrl}
              alt={image.altText ? image.altText : ''}
            />
          )}
        </div>
        <div>
          <p className={'font-semibold text-base'}>{name}</p>
          <p className={'text-sm'}>{jobTitle}</p>
        </div>
      </div>
      <div>
        <div className={'text-sm lg:text-base'}>{quote}</div>
      </div>
    </Root>
  )
}

export default TestimonialCard

TestimonialCard.propTypes = {
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  image: PropTypes.object,
  quote: PropTypes.array,
}
