import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import UniqueSellingPoints from '../components/unique-selling-points'
import Aside from '../components/aside'
import Slider from '../components/courses/course/slider'
import TestimonialsSlider from '../components/testimonials/slider'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

const FrontPage = ({ data, location }) => {
  const { wpPage: page } = data
  const base = page.template.templateFrontPage
  const usps = base.uniqueSellingPointsList
  const testimonials = base.testimonialsList
  const courses = data.allWpCourse.nodes

  const asides = base.asides ?? []

  const futureCourses = courses.filter((course) => {
    return new Date(course.templateCourse.date) > new Date()
  })

  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <Hero
        titleTag={base.title.headingTag}
        titleText={base.title.text}
        subtitleTag={base.subtitle.headingTag}
        subtitleText={base.subtitle.text}
        buttons={base.buttons}
        image={base.image}
        notification={
          data.wp.sailGlobalSettings.globalSettings.notificationMessage
        }
      />
      <UniqueSellingPoints data={usps} />

      {asides.length > 0 && (
        <div id={'asides'}>
          {asides.map((item, index) => (
            <Aside
              key={index}
              index={index}
              buttons={item.buttonList}
              title={item.title}
              content={item.content}
              media={item.media}
              isOutlined={false}
              id={item.id}
            />
          ))}
        </div>
      )}

      {futureCourses.length > 0 && (
        <Slider
          list={futureCourses}
          title={base.coursesTitle}
          subtitle={base.coursesSubtitle}
        />
      )}
      {testimonials && (
        <TestimonialsSlider
          title={base.testimonialsTitle}
          list={testimonials}
        />
      )}
    </Layout>
  )
}

export default FrontPage

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        metaDesc
        title
      }
      template {
        ... on WpTemplate_FrontPage {
          templateName
          templateFrontPage {
            coursesTitle
            coursesSubtitle
            testimonialsTitle
            testimonialsList {
              jobTitle
              name
              quote
              visible
              image {
                title
                publicUrl
              }
            }
            asides {
              id
              title
              content
              buttonList {
                variant
                link {
                  target
                  title
                  url
                }
              }
              media {
                mediaType
                image {
                  publicUrl
                  sourceUrl
                  title
                }
                video {
                  sourceUrl
                  publicUrl
                  title
                }
              }
            }
            buttons {
              link {
                url
                title
                target
              }
              variant
            }
            image {
              sourceUrl
              publicUrl
            }
            subtitle {
              headingTag
              text
            }
            title {
              headingTag
              text
            }
            uniqueSellingPointsList {
              icon {
                sourceUrl
                publicUrl
                title
                altText
              }
              text
              link {
                url
                title
                target
              }
            }
          }
        }
      }
    }
    allWpCourse(sort: { fields: templateCourse___date, order: ASC }) {
      nodes {
        templateCourse {
          date
          digital
          link {
            title
            url
            target
          }
          place
        }
        title
        locations {
          nodes {
            name
            slug
            taxonomyName
          }
        }
        userGroups {
          nodes {
            taxonomyName
            name
            slug
          }
        }
      }
    }
    wp {
      sailGlobalSettings {
        globalSettings {
          notificationMessage
        }
      }
    }
  }
`
