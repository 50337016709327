import tw, { styled } from 'twin.macro'

export const Root = styled.div`
  ${tw`
  p-6
  bg-white
  gap-y-4
  text-left
  
  w-[290px]
  
  xl:w-[420px]
  
  rounded-lg 
  flex 
  flex-col 
  justify-start
  items-start
  content-start
  place-items-start
  place-content-start
  shadow-1
`}
`
