import React from 'react'
import { SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

import TestimonialCard from '../card'
import { Root, SliderContainer } from './style'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { sliderStopAndRead } from '../../google-tag-manager/events'

const generalSettings = {
  type: 'loop',
  width: '100%',
  height: '100%',
  gap: '2rem',
  autoWidth: true,
  trimSpace: true,
  arrows: false,
  easing: 'linear',
  // autoplay: true,
  autoScroll: {
    pauseOnHover: false,
    pauseOnFocus: false,
    rewind: false,
    speed: 0.5,
  },
  onDragEnd: sliderStopAndRead('testimonials'),
  padding: '0rem',
  interval: 3,
  drag: 'free',
  breakpoints: {
    376: {
      gap: '1rem',
    },
    1536: {
      gap: '1.5rem',
    },
  },
}

const TestimonialsSlider = ({ title, list }) => {
  const middle = Math.ceil(list.length / 2)
  const top = list.slice(0, middle)
  const bottom = list.slice(-middle)

  return (
    <Root id={'testimonials'}>
      <div className={'text-center w-full px-4 md:px-6'}>
        <h3
          className={'font-medium text-orange-dark mb-10 text-4xl lg:text-5xl'}
        >
          {title}
        </h3>
      </div>

      {top && (
        <SliderContainer
          extensions={{ AutoScroll }}
          tag={'section'}
          hasTrack={false}
          aria-label={'first testimonial slider'}
          options={{
            ...generalSettings,
            direction: 'rtl',
          }}
        >
          <div className={'h-max'}>
            <SplideTrack>
              {top.map((item, index) => (
                <React.Fragment key={index}>
                  {item.visible && (
                    <SplideSlide
                      key={index}
                      className={'pt-4 mb-0 pb-3 lg:pb-4'}
                    >
                      <TestimonialCard
                        name={item.name}
                        jobTitle={item.jobTitle}
                        quote={parse(item.quote)}
                        image={item.image}
                      />
                    </SplideSlide>
                  )}
                </React.Fragment>
              ))}
            </SplideTrack>
          </div>
        </SliderContainer>
      )}
      {bottom && (
        <SliderContainer
          extensions={{ AutoScroll }}
          tag={'section'}
          hasTrack={false}
          aria-label={'second testimonial slider'}
          options={{
            ...generalSettings,
            direction: 'ltr',
          }}
        >
          <div>
            <SplideTrack>
              {bottom.map((item, index) => (
                <React.Fragment key={index}>
                  {item.visible && (
                    <SplideSlide key={index} className={'pb-4'}>
                      <TestimonialCard
                        name={item.name}
                        jobTitle={item.jobTitle}
                        quote={parse(item.quote)}
                        image={item.image}
                      />
                    </SplideSlide>
                  )}
                </React.Fragment>
              ))}
            </SplideTrack>
          </div>
        </SliderContainer>
      )}
    </Root>
  )
}

export default TestimonialsSlider

TestimonialsSlider.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
}
